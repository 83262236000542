.footer {
  text-align: left;
  flex-shrink: 0; /* Ensures the footer doesn't shrink */
  width: 100%;
  color: #666;
  margin-top: auto; /* keeps the footer at the bottom */
  /* background-color: #f9f9f9; Lighter background for contrast */
  /* box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); */
}

#footer-4-ubtcR6XhWT {
  background-color: #260a30;
}

.footer p {
  padding-left: 50px;
}

@media (max-width: 768px) {
  .footer {
    text-align: center;
  }
  .footer p {
    padding-left: 0;
  }
}

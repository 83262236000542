.MainContent {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  padding: 0 50px 50px 50px;
  flex-wrap: wrap; /* Ensure it wraps on smaller screens */
}

.Description {
  flex: 1 1 50%; /* Flex grow, flex shrink, flex basis */
  min-width: 300px; /* Minimum width of the description block */
  margin-right: 20px; /* Add space between the description and the form */
  padding: 10px;
  font-size: 54px;
  text-align: left;
  line-height: 60px;
  color: white;
  font-weight: bolder;
}

.Description p {
  margin-top: 0;
}

.contact-form {
  flex: 1 1 40%; /* Flex grow, flex shrink, flex basis */
  min-width: 340px; /* Minimum width of the form */
  padding: 20px;
}

@media screen and (max-width: 938px) {
  .MainContent {
    flex-direction: column;
    align-items: center; /* Center align items vertically on small screens */
    padding-top: 0;
  }

  .Description,
  .contact-form {
    width: auto;
    margin: 0;
    min-width: 0; /* Removes the minimum width */
  }

  .Description {
    text-align: center;
    padding: 0px;
    margin-bottom: 10px;
    font-size: 40px;
    line-height: 50px;
  }
  .Description p {
    margin: 0px;
  }
}

/* .header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  padding: 1rem;
  box-sizing: border-box;
  width: 100%;
} */
.header {
  background-color: #a1bffa; /* Calm Teal Background */
  text-align: center; /* Aligns the logo to the left */
  height: 50%;
}

.header img {
  width: 320px;
}

.header h2 {
  margin-top: 0;
}

.header p {
  max-width: 600px; /* Restricting the width of paragraphs */
  margin: 0 auto; /* Centers the paragraph if it's narrower than the container */
  padding-bottom: 20px; /* Adds some space below the paragraph */
}

.tagline {
  font-style: italic;
  color: #555;
  margin-bottom: 1em;
}
